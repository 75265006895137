import {
  FacetInput,
  FacetSelectionInput,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { FacetType } from '@thg-commerce/enterprise-widget-product-list-page'

export const createFacetSelectionArray = (
  facetInput: FacetInput[],
  facets: FacetType[],
): FacetInput[] => {
  return facets.map((facet) => {
    const currentSelections =
      facetInput.find(
        (currentFacet) => currentFacet.facetName === facet.facetName,
      )?.selections || []
    return {
      facetName: facet.facetName,
      selections: [...currentSelections],
    }
  })
}

export const getFacetInputFromUrl = (facetFilters: string): FacetInput[] => {
  if (!facetFilters) {
    return []
  }

  return Object.values(
    facetFilters
      .replace(/\+/g, ' ')
      .replace(/&amp;/g, '&')
      .split('|')
      .reduce((acc, facet) => {
        const [facetName, selectionString] = facet.split(':')

        if (!facetName?.trim() || !selectionString?.trim()) {
          return ''
        }

        const selection = getFacetSelection(selectionString)

        acc[facetName]
          ? acc[facetName].selections.push(selection)
          : (acc[facetName] = {
              facetName,
              selections: [selection],
            })

        return acc
      }, {}),
  )
}

const getFacetSelection = (selectionString: string): FacetSelectionInput => {
  if (
    selectionString.slice(0, 1) + selectionString.slice(-1) === '[]' &&
    selectionString.includes('TO')
  ) {
    const selectionNums = selectionString
      .slice(1, -1)
      .split(' TO ')
      .map((num) => (num === '*' ? null : parseFloat(num)))
    return { from: selectionNums[0], to: selectionNums[1] }
  }

  return { optionName: selectionString }
}
