import {
  getSafeUrlParametersFromWindow,
  LIST_EXTENSION,
} from '@thg-commerce/enterprise-core'
import {
  ProductList,
  ProductSort,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { ProductCategoryItemProps } from '@thg-commerce/enterprise-widget-product-categories'
import { ChangeType } from '@thg-commerce/enterprise-widget-product-list-page'
import { getFacetInputFromUrl } from '@thg-commerce/enterprise-widgets/src/ProductListWidgetRenderer/utils'

export const sortOrderOptions = (data, experiments) => {
  const sortOrder = data || null

  if (!experiments) {
    return sortOrder || ProductSort.Relevance
  }

  const experimentSortOrder =
    experiments['web_default_sort'] === 'v1|default_sort_relevance'
      ? ProductSort.Relevance
      : experiments['web_default_sort'] === 'v2|default_sort_popularity'
      ? ProductSort.Popularity
      : null

  return sortOrder || experimentSortOrder || ProductSort.Relevance
}

export const sortTypeTexts = (i18nHelper) => [
  {
    type: ProductSort.Relevance,
    displayText: i18nHelper('product.list.sort.relevance'),
  },
  {
    type: ProductSort.Popularity,
    displayText: i18nHelper('product.list.sort.popularity'),
  },
  {
    type: ProductSort.PriceLowToHigh,
    displayText: i18nHelper('product.list.sort.pricelowtohigh'),
  },
  {
    type: ProductSort.PriceHighToLow,
    displayText: i18nHelper('product.list.sort.pricehightolow'),
  },
  {
    type: ProductSort.NameAlphabetically,
    displayText: i18nHelper('product.list.sort.namealphabetically'),
  },
  {
    type: ProductSort.NewestToOldest,
    displayText: i18nHelper('product.list.sort.newesttooldest'),
  },
  {
    type: ProductSort.DiscountPercentageHighToLow,
    displayText: i18nHelper('product.list.sort.discountpercentagehightolow'),
  },
]

const sortedTypesOptions = (i18nHelper) =>
  sortTypeTexts(i18nHelper)?.filter(
    (item) =>
      item.type !== ProductSort.Relevance &&
      item.type !== ProductSort.Popularity,
  )

export const sortTypesOptions = (experiments, i18nHelper) => {
  const relevanceItem = {
    type: ProductSort.Relevance,
    displayText: i18nHelper('product.list.sort.relevancetitle'),
  }

  const popularityItem = {
    type: ProductSort.Popularity,
    displayText: i18nHelper('product.list.sort.popularity'),
  }

  const isRelevanceDefault =
    experiments?.['web_default_sort'] === 'v1|default_sort_relevance'
  const isPopularityDefault =
    experiments?.['web_default_sort'] === 'v2|default_sort_popularity'

  if (isRelevanceDefault) {
    return [relevanceItem, popularityItem, ...sortedTypesOptions(i18nHelper)]
  }

  if (isPopularityDefault) {
    return [popularityItem, relevanceItem, ...sortedTypesOptions(i18nHelper)]
  }

  return sortTypeTexts(i18nHelper)
}

export const processedProductList = (data) =>
  (data?.productListProducts?.page?.widgets
    ?.filter((widget) => widget['__typename'] === 'ProductListWidget')
    .pop() as unknown) as {
    description: string | null
    seoDescription: string | null
    products: ProductList
  }

export const breadcrumbs = (breadcrumbsData) =>
  breadcrumbsData.map((item) => {
    return {
      text: item.displayName,
      url:
        item.pagePath === '/'
          ? item.pagePath
          : item.pagePath.concat(LIST_EXTENSION),
    }
  })

export const breadcrumbsSchema = (breadcrumbsData, originUrl) =>
  breadcrumbsData.map((breadcrumb, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      name: breadcrumb.text,
      item: originUrl.concat(breadcrumb.url),
    }
  })

export const topProductCategorySet = (data) =>
  data.page?.widgets?.find(
    (item) => item['__typename'] === 'TopProductCategorySet',
  ) as any

export const productCategoryItems = (data) =>
  (topProductCategorySet(data)
    ?.banners?.filter(
      (item) => item.topProductCategoryName && item.topProductCategoryUrl,
    )
    .map((item) => {
      return {
        text: item.topProductCategoryName as string,
        href: item.topProductCategoryUrl as string,
      }
    }) || []) as ProductCategoryItemProps[]

export const processedProducts = (data) =>
  (processedProductList(data)?.products &&
    processedProductList(data).products.products) ||
  []

export const processedProductListData = (
  data,
  setProductList,
  productListData,
  productItemsPerPage,
  sortOptions,
) => {
  const url = new URL(window.location.href)
  setProductList({
    // TODO: conditionally merge when products exist
    products: processedProducts(data),
    itemsPerPage: productItemsPerPage,
    title: productListData.productList?.title || '',
    description: productListData.productList?.description || '',
    seoDescription: productListData.productList?.seoDescription || '',
    changeType: ChangeType.NONE,
    categories: productCategoryItems(data),
    categoriesTitle: topProductCategorySet(data)?.DiscoverTitle || null,
    facets: processedProductList(data)?.products?.facets,
    pageOptions: {
      sort: sortOptions,
      facets: getFacetInputFromUrl(url.searchParams.get('facetFilters') || ''),
      pageNumber:
        getSafeUrlParametersFromWindow<{ pageNumber: string }>(
          window,
          'pageNumber',
        ).pageNumber || 1,
    },
    totalItems:
      processedProductList(data)?.products?.total ||
      processedProducts(data).length ||
      0,
  })
}

export const getTagKeys = (contentKeys, tagKeys) => {
  if (!contentKeys || !tagKeys) return []

  return contentKeys.filter((contentKey) => tagKeys.includes(contentKey))
}
